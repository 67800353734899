<template>
  <div>
    <v-container pt-2 pb-1>
      <page-title :title="pageTitle" />
    </v-container>

    <liff-list
      :listKey="listKey"
      :toolbar="false"
      :cardBtnActions="cardBtnActions"
    ></liff-list>

    <PrimaryModal :show="request.switch" @close="close">
      <v-card-title class="d-flex justify-center">
        <span class="pb-5">{{ $t("refund.ask") }}</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn depressed outlined class="mr-3" @click="close()">
            {{ $t(`action.cancel`) }}
          </v-btn>
          <v-btn color="red" depressed outlined @click="submit()">{{
            $t("action.refunded-submit")
          }}</v-btn>
        </div>
      </v-card-text>
    </PrimaryModal>
  </div>
</template>

<script lang="babel" type="text/babel">
import _cloneDeep from "lodash/cloneDeep";
import listMixin from "@/components/list/mixins/listMixin.js";
import listConfig from "./providerRefundList";
import PrimaryModal from "components/modal/primaryModal.vue";

export default {
  mixins: [listMixin],
  components: { PrimaryModal },
  data: () => ({
    listKey: "liff-provider-refund-list",
    meta: {},
    request: {
      switch: false,
    },
    currRefundPaymentRecordId: null,
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`];
    },
    cardBtnActions() {
      return {
        click: this.click,
      };
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([]);
    },
    async indexApi() {
      return await this.$api.collection.providerApi.getPendingRefundList(
        this.providerId
      );
    },
    getListConfig() {
      return listConfig;
    },
    click(id = null) {
      this.currRefundPaymentRecordId = id;
      this.request.switch = true;
    },
    close() {
      this.request.switch = false;
    },
    async submit() {
      this.$store.dispatch("loading/active");
      try {
        this.$api.collection.providerApi.refundOrder(
          this.currRefundPaymentRecordId
        );
        this.$snotify.success("完成退款");
        this._indexAction();
      } catch (error) {
        console.log(error);
        this.$snotify.error("完成退款功能異常");
      } finally {
        this.currRefundPaymentRecordId = null;
        this.request.switch = false;
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
