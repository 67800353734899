import Vue from "vue";
import { LiffListConfigInterface } from "@/components/list/types/list";
import orderTab from "@/modules/base/config/orderTab.ts";

class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      searchFilterOrder: null,
      targetKey: "order_no",
      pageTitle: "module.refund",
      search: "order.search_keyword",
      sort: [{ label: "data.created_at", key: "created_at" }],
      liffFilter: {
        status: {
          type: "tab",
          options: () => {
            return orderTab;
          },
        },
      },
      listItem: () =>
        import("@/modules/provider/views/refund/refundCardItem.vue"),
      useRow: false,
      batch: {},
      selectedData: (row) => row,
    };
  }
}

export default new listConfig();
